import React, { Component } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";
import { Media, MediaContextProvider } from '../../media';
import { Link } from "react-router-dom";
import './homeSilde.scss';

 const HeaderHomeSilde = React.memo(() => {

    const { t } = useTranslation(['translation', 'home'])

    const settings = {
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay:true,
        autoplaySpeed:5000,
        pauseOnHover:false
    };
    
    return (
      <div id="header-home-silde">
        <MediaContextProvider>
        <Slider {...settings}>
          <div >
            <Media greaterThanOrEqual="md">
                <div className="text-center pt-5 pb-5 align-items-center justify-content-center" style={{background: `url(${asset('/images/homeSilde/Home.png')}) no-repeat center top`,backgroundSize:'cover',height:'120vh' }}>
                    <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:State-Of-The-Art Technology') }} />
                    <p className="w-50 pt-3 pb-3" style={{margin:'auto'}} dangerouslySetInnerHTML={{ __html: t('home:Connect seamlessly from Property Developers, Juristic, Committee, Property Management Company, Accountants, Engineers, Property Managers, Security Guards, Maids to Co-owners, Members, Residents, and Tenants. Including Real Estate Agents such as Buyers, Sellers, Lessors, and Lessees, all in one comprehensive platform!') }}/>
                    <div className="app-download d-flex align-items-center justify-content-center">
                    <div className="pr-3">
                        <a href="https://play.google.com/store/apps/details?id=com.port80.silverman&hl=en&gl=US" target="_blank" rel="noopener noreferrer" className="">
                            <Image src={asset('/images/homeSilde/googleplay.png')} alt="silverman app dowload" width="205" height="76" className="d-block w-auto" />
                         </a>
                    </div>
                    <div>
                        <a href="https://apps.apple.com/th/app/silverman/id1436707310" target="_blank" rel="noopener noreferrer" className="">
                            <Image src={asset('/images/homeSilde/appstore.png')} alt="silverman app dowload" width="205" height="76" className="d-block w-auto" />
                        </a>
                    </div>

                   
                    </div>
                </div>
            </Media>
            <Media lessThan="md">
                <div className="text-center pt-5 align-items-center justify-content-center container" >
                    <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:State-Of-The-Art Technology') }} />
                    <p className="w-75 pt-3" style={{margin:'auto'}}  dangerouslySetInnerHTML={{ __html: t('home:Connect seamlessly from Property Developers, Juristic, Committee, Property Management Company, Accountants, Engineers, Property Managers, Security Guards, Maids to Co-owners, Members, Residents, and Tenants. Including Real Estate Agents such as Buyers, Sellers, Lessors, and Lessees, all in one comprehensive platform!') }} />
                </div>
                <div className="pt-2">
                        <img src={asset('/images/homeSilde/home-silde-mobile.png')} alt="home" className="img-fluid" />
                </div>
            </Media>
          </div> 


        {/* debt collection */}
          <div>
          <Media greaterThanOrEqual="md">
                <div className="text-center pt-5 pb-5 d-flex flex-column justify-content-between" style={{background: `url(${asset('/images/homeSilde/debt_collection_homepage.webp')}) no-repeat center top`,backgroundSize:'cover',height:'120vh' }}>
                    <div>
                        <h1 className="w-75 text-left font-detail56 pt-3 pb-3 mx-auto" dangerouslySetInnerHTML={{ __html: t('home:Central fee debt tracking system') }}/>
                        <p className="w-75 text-left font-detail30 pt-3 pb-3 mx-auto" 
                        dangerouslySetInnerHTML={{ __html: t(`home:Services for issuing E-NOTICE`) 
                        }} />
                        <div className="app-download d-flex align-items-center pt-4 w-75 mx-auto">
                            <div className="pr-4">
                                <Link to="/register">
                                    <button className="btn btn-primary">
                                        <span dangerouslySetInnerHTML={{ __html: t('home:Register') }}/> 
                                    </button>
                                </Link>
                            </div>
                            <div>
                                <a href="https://line.me/ti/p/%40silvermanapp" target="_blank" rel="noopener noreferrer">
                                    <button className="btn btn-light-white">
                                    <span dangerouslySetInnerHTML={{ __html: t('home:Contact us') }}/> 
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="confirmBy d-flex flex-column">
                        <h1 className="w-50 pb-2 font-head48" dangerouslySetInnerHTML={{ __html: t('home:By a lawyer') }} />
                        <h1 className="w-50 pb-3 font-head48" dangerouslySetInnerHTML={{ __html: t('home:Professional real estate services.') }} />
                        <div className="w-50 d-flex flex-row justify-content-end">
                            <a href="/price-and-package/debt-law-service" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-primary">
                                    <span dangerouslySetInnerHTML={{ __html: t('home:View details.') }}/> 
                                </button>
                            </a>
                        </div>
                    </div>

                    <div>
                        <h1 className="font-head56 font-head-blue" dangerouslySetInnerHTML={{ __html: t('home:Expedited - Fast - No loss in legal action.') }} />
                    </div>
                </div>
            </Media>
            <Media lessThan="md">
                <div className="text-center pt-5 align-items-center justify-content-center container" >
                <h1 className="text-header" 
                    dangerouslySetInnerHTML={{ __html: t('home:Central fee debt tracking system')}}/>               
                <p className="w-75 pt-2" style={{margin:'auto'}} 
                    dangerouslySetInnerHTML={{ __html: t('home:Services for issuing E-NOTICE')}}/>             
                <a href="/price-and-package/debt-law-service" target="_blank" rel="noopener noreferrer">
                        <button className="btn btn-primary mt-2">
                        <span dangerouslySetInnerHTML={{ __html: t('home:View details.') }}/> 
                    </button>
                </a>                    
                </div>
                
                <div className="pt-2">
                        <img src={asset('/images/homeSilde/debt_collection_homepage_mobile.webp')} alt="home" className="img-fluid" />
                </div>
            </Media>
          </div>

          {/* LPR */}
          <div>
          <Media greaterThanOrEqual="md">
                <div className="text-center pt-5 pb-5 d-flex flex-column justify-content-between" style={{background: `url(${asset('/images/homeSilde/LPR_homepage.webp')}) no-repeat center top`,backgroundSize:'cover',height:'120vh' }}>
                    <div>
                        <h1
                            className="w-80 text-left font-detail56 pt-3 mx-auto"
                            dangerouslySetInnerHTML={{ __html: t('home:silverman_guard_lpr') }}
                        />
                        <h1
                            className="w-80 text-left font-detail56 pt-3 pb-3 mx-auto"
                            dangerouslySetInnerHTML={{ __html: t('home:Pay a comfortable monthly fee, starting from just a few thousand baht per month.') }}
                        />

                        <div className="app-download d-flex flex-column align-items-end pt-4 w-80 mx-auto">
                            <div className="w-60">
                                <h1 className="text-left font-head56 font-head-blue" dangerouslySetInnerHTML={{ __html: t('home:To reduce security guard hiring costs.') }} />
                                <h1 className="text-left font-head56 font-head-blue" dangerouslySetInnerHTML={{ __html: t('home:Save up to') }} />
                            </div>
                            <div className="w-60 mt-2">
                                <p className="font-detail30 text-left" dangerouslySetInnerHTML={{__html: t('home:Enhancing convenience')}} />
                                <div className="d-flex flex-row mt-4">
                                    <div className="pr-4">
                                        <Link to="/register">
                                            <button className="btn btn-primary">
                                                <span dangerouslySetInnerHTML={{ __html: t('home:Register') }}/> 
                                            </button>
                                        </Link>
                                    </div>
                                    <div>
                                        <a href="https://line.me/ti/p/%40silvermanapp" target="_blank" rel="noopener noreferrer">
                                            <button className="btn btn-light-white">
                                            <span dangerouslySetInnerHTML={{ __html: t('home:Contact us') }}/> 
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="confirmBy d-flex flex-column">
                        <div className="d-flex flex-row">
                            <h1 className="pb-2 mr-5 font-head48 font-head-blue" dangerouslySetInnerHTML={{ __html: t('home:No lump sum required.') }} />
                            <h1 className="pb-2 mr-5 font-head48 font-head-blue" dangerouslySetInnerHTML={{ __html: t('home:No need to increase the common area fee.') }} />
                        </div>
                        <div className="d-flex flex-row">
                        <h1 className="pb-2 mr-5 font-head48 font-head-blue" dangerouslySetInnerHTML={{ __html: t('home:Save immediately.') }} />
                        <h1 className="pb-2 mr-5 font-head48 font-head-blue" dangerouslySetInnerHTML={{ __html: t('home:Save every month.') }} />
                        <h1 className="pb-2 mr-5 font-head48 font-head-blue" dangerouslySetInnerHTML={{ __html: t('home:Reduce the common area fee.') }} />
                            <div className="d-flex flex-row justify-content-end">
                                <a href="/price-and-package/visitor-control" target="_blank" rel="noopener noreferrer">
                                    <button className="btn btn-primary">
                                        <span dangerouslySetInnerHTML={{ __html: t('home:ดูรายละเอียด') }}/> 
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h1 className="font-head56" dangerouslySetInnerHTML={{ __html: t("home:What are you waiting for? You don't need to wait for the general meeting to change the system—make the change today!") }} />
                    </div>
                </div>
            </Media>
            <Media lessThan="md">
                <div className="text-center pt-5 align-items-center justify-content-center container" >
                <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:silverman_guard_lpr') }} />
                <p className="pt-2" style={{margin:'auto'}} dangerouslySetInnerHTML={{ __html: t('home:Pay a comfortable monthly fee, starting from just a few thousand baht per month.') }} />
                <p dangerouslySetInnerHTML={{__html: t('home:Enhancing convenience')}} />
                <a href="/price-and-package/visitor-control" target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-primary">
                        <span dangerouslySetInnerHTML={{ __html: t('home:View details.') }}/> 
                    </button>
                </a>
                </div>
                <div className="pt-2">
                    <img src={asset('/images/homeSilde/LPR_homepage_mobile.webp')} alt="home" className="img-fluid" />
                </div>
            </Media>
          </div>                        

          {/* <div>
          <Media greaterThanOrEqual="md">
                <div className="text-center pt-5 pb-5 align-items-center justify-content-center" style={{background: `url(${asset('/images/homeSilde/Accounting_03.png')}) no-repeat center top`,backgroundSize:'cover',height:'120vh' }}>
                    <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:The accounting statements you see...<br/>might not be as you expect.') }}/>
                    <p className="w-75 pt-3 pb-3" style={{margin:'auto'}} dangerouslySetInnerHTML={{ __html: t(`home:Don't believe anyone until you've tried Silverman property management solution. And most importantly, <br/>don't hesitate, don't wait. Make the decision to start using our software today.<br/>If you don't want your projects to face such a situation in the future, take action now.<br/>Join us on the path to a different and better financial future for your project!<br/>`) }} />
                    <div className="app-download d-flex align-items-center justify-content-center pt-4">
                        <div className="pr-4">
                            <Link to="/register">
								<button className="btn btn-primary">
                                    <span dangerouslySetInnerHTML={{ __html: t('home:Register') }}/> 
								</button>
							</Link>
                        </div>
                        <div>
                            <a href="https://line.me/ti/p/%40silvermanapp" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-light-white">
                                <span dangerouslySetInnerHTML={{ __html: t('home:Contact us') }}/> 
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </Media>
            <Media lessThan="md">
                <div className="text-center pt-5 align-items-center justify-content-center container" >
                <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:The accounting statements you see...<br/>might not be as you expect.')}}/>               
                <p className="w-75 pt-2" style={{margin:'auto'}} dangerouslySetInnerHTML={{__html:t(`home:Don't believe anyone until you've tried Silverman property management solution. And most importantly, <br/>don't hesitate, don't wait. Make the decision to start using our software today.<br/>If you don't want your projects to face such a situation in the future, take action now.<br/>Join us on the path to a different and better financial future for your project!<br/>`)}} />
                </div>
                <div className="pt-2">
                        <img src={asset('/images/homeSilde/home-acounting-mobile-2-new.png')} alt="home" className="img-fluid" />
                </div>
            </Media>
          </div> */}
          <div>
          <Media greaterThanOrEqual="md">
                <div className="pt-5 pb-5 align-items-left justify-content-left" style={{background: `url(${asset('/images/homeSilde/Accounting_02.png')}) no-repeat center top`,backgroundSize:'cover',height:'120vh' }}>
                <div className="offset-md-1 ">
                <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:When you seek transparency,<br/>reliability, and enduring excellence<br/>in professional accounting.')}}/>
                <p className="pt-3 pb-3" dangerouslySetInnerHTML={{ __html: t(`home:and comprehensive auditing services for your juristic and property management, there's nothing<br/> quite like what we offer! Our services come with a guarantee of sustainability and lasting value<br/> for your projects. Trustworthy and backed by expertise, we are dedicated to providing exceptional<br/> service. Rest assured, Silverman, we are your steadfast companion on the path to your success!`) }}/>
                <div className="d-flex align-items-left justify-content-left pt-4 ">
                        <div className="pr-4">
                            <Link to="/register">
								<button className="btn btn-primary">
                                    <span dangerouslySetInnerHTML={{ __html: t('home:Register') }}/> 
								</button>
							</Link>
                        </div>
                        <div>
                            <a href="https://line.me/ti/p/%40silvermanapp" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-light">
                                    <span dangerouslySetInnerHTML={{ __html: t('home:Contact us') }}/> 
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                </div>
            </Media>
            <Media lessThan="md">
                <div className="text-center pt-5 align-items-center justify-content-center container" >
                <h1 className="text-header" dangerouslySetInnerHTML={{ __html: t('home:When you seek transparency,<br/>reliability, and enduring excellence<br/>in professional accounting.')}}/>
                <p className="pt-3 pb-3 text-left" dangerouslySetInnerHTML={{ __html: t(`home:and comprehensive auditing services for your juristic and property management, there's nothing<br/> quite like what we offer! Our services come with a guarantee of sustainability and lasting value<br/> for your projects. Trustworthy and backed by expertise, we are dedicated to providing exceptional<br/> service. Rest assured, Silverman, we are your steadfast companion on the path to your success!`) }}/>
                </div>
                <div className="pt-2">
                        <img src={asset('/images/homeSilde/home-acounting-mobile-3.png')} alt="home" className="img-fluid" />
                </div>
            </Media>
          </div>
          
        </Slider>
        
        </MediaContextProvider>
      </div>
     
    );
})
    
export default HeaderHomeSilde